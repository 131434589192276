/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { head } from 'lodash-es'
import uniqid from 'uniqid'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import * as styles from './style.module.scss'
import Seo from '~components/globals/Seo'
import SubscriptionBanner from '~components/SubscriptionBanner'
import Container from '~components/globals/Container'
import PageHeader from '~components/globals/PageHeader'

export const Imprint = ({
  data: {
    prismicImprint: { data },
    allPrismicPage,
  },
  location,
}) => {
  const { content, headline } = data
  const { sharing_description, sharing_image, sharing_title } = allPrismicPage.edges[0].node.data

  return (
    <Container>
      <Seo
        title={sharing_title.text}
        description={sharing_description.text}
        url={location.href}
        image={sharing_image.url}
      />
      <Container extraClassName={styles.container}>
        <PageHeader
          bgColor="#fff"
          extraClassname={styles.pageHeader}
          textColor="#000"
          headline={headline.text}
        />
        <div data-html className={styles.textContainer}>
          {parse(content.html)}
        </div>
        {/* {content.map(({ text, type }) => {
          if (type === 'paragraph') {
            return (
              <p dangerouslySetInnerHTML={{ __html: text }} className={styles.bodyText} key={uniqid()} />
            )
          }
          if (type === 'heading3') {
            return (
              <h3 className={styles.bodyHeading} key={uniqid()}>
                {text}
              </h3>
            )
          }
          return null
        })} */}
      </Container>
      <SubscriptionBanner />
    </Container>
  )
}

export default Imprint

export const pageQuery = graphql`
  query {
    prismicImprint {
      data {
        headline {
          text
        }
        content {
          html
        }
      }
    }
    allPrismicPage(filter: {data: {slug: {eq: "imprint"}}}) {
      edges {
        node {
          data {
            sharing_description {
              text
            }
            sharing_title {
              text
            }
            sharing_image {
              url
            }
          }
        }
      }
    }
  }
`
Imprint.propTypes = {
  data: PropTypes.shape({
    prismicImprint: PropTypes.shape({
      data: PropTypes.shape({
        headline: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
          }),
        ),
        content: PropTypes.arrayOf(
          PropTypes.shape({
            alt: PropTypes.string,
            text: PropTypes.string,
            type: PropTypes.string.isRequired,
            url: PropTypes.string,
          }),
        ),
      }),
    }),
    prismicPage: PropTypes.shape({
      data: PropTypes.shape({
        sharing_title: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
        sharing_description: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
        sharing_image: PropTypes.shape({ url: PropTypes.string }),
      }),
    }),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
}
