// extracted by mini-css-extract-plugin
export var eyebrow = "style-module--eyebrow--1LV_H";
export var ctBlack = "style-module--ctBlack--2S828";
export var ctSmall = "style-module--ctSmall--3iLSM";
export var ctMedium = "style-module--ctMedium--2CTuq";
export var bodyHeading = "style-module--bodyHeading--1Rk8H";
export var textContainer = "style-module--textContainer--5sToV";
export var ctLarge = "style-module--ctLarge--1ZTqB";
export var ctXLarge = "style-module--ctXLarge--x5kJf";
export var ctIntro = "style-module--ctIntro--3yhus";
export var introText = "style-module--introText--33HoF";
export var ctBody = "style-module--ctBody--1pz9S";
export var bodyText = "style-module--bodyText--3KqrU";
export var ctBodyMedium = "style-module--ctBodyMedium--E6wIv";
export var ctBodyBold = "style-module--ctBodyBold--1TGv8";
export var ctLink = "style-module--ctLink--H7R78";
export var frutiLarge = "style-module--frutiLarge--3Uoft";
export var ctUi = "style-module--ctUi--2sQ_p";
export var ctMeta = "style-module--ctMeta--1SGgV";
export var ctMicro = "style-module--ctMicro--4aLC2";
export var ctMediumItalic = "style-module--ctMediumItalic--1w-7M";
export var container = "style-module--container--1-1xN";
export var pageHeader = "style-module--pageHeader--1rab2";
export var blockImg = "style-module--block-img--3s-8s";
export var imageContainer = "style-module--imageContainer--25lRQ";
export var bodyImage = "style-module--bodyImage--3aWvL";